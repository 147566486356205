import { HTML_ATTRIBUTE_COOKIE_IDS } from ".";
import { OPT_IN_CONTENT_BLOCKER_ALL } from "..";
/**
 * Listen to clicked content blockers and automatically delegate the click
 * event to the underlying element. This is especially useful for overlays of videos
 * so the user does not need to click twice.
 */

function delegateClick(element, {
  same,
  nextSibling,
  parentNextSibling
}) {
  let delegateClick;
  const nextElementSibling = element.nextElementSibling;
  const parentElement = element.parentElement;
  const parentElementNextSibling = parentElement === null || parentElement === void 0 ? void 0 : parentElement.nextElementSibling;

  loop1: for (const [node, selectors] of [[// Same element (= the blocked element)
  element, same], [// Next sibling element to the blocked content
  nextElementSibling, nextSibling], [// Next sibling element of parent of the blocked content
  parentElementNextSibling, parentNextSibling]]) {
    if (node && selectors) {
      for (const selector of selectors) {
        if (node.matches(selector)) {
          delegateClick = node;
          break loop1;
        } // Check for children selector


        const childrenSelected = node.querySelector(selector);

        if (childrenSelected) {
          delegateClick = childrenSelected;
          break loop1;
        }
      }
    }
  } // We delegate the click to an element, let's check if it is also blocked and listen to unblock


  if (delegateClick) {
    const fn = () => setTimeout(() => delegateClick.click(),
    /* Let's delay so we can start after `manipulateDom#customTriggers()` */
    100);

    if (delegateClick.hasAttribute(HTML_ATTRIBUTE_COOKIE_IDS)) {
      delegateClick.addEventListener(OPT_IN_CONTENT_BLOCKER_ALL, fn, {
        once: true
      });
    } else {
      fn();
    }
  }
}

export { delegateClick };