import { unblock } from "../../api";
import { OPT_IN_CONTENT_BLOCKER } from "@devowl-wp/headless-content-unblocker";
const APIS = ["youtube", "vimeo"];
/**
 * Overwrite the video API of elementor to make it GDPR compliant. It also implements
 * auto-play mechanism when clicking the content blocker.
 *
 * @see https://github.com/elementor/elementor/pull/12567
 * @see https://git.io/Js1em
 * @see https://git.io/Js1Eu
 */

function overwriteElementorVideoApi() {
  const w = window;
  const {
    jQuery
  } = w;
  jQuery === null || jQuery === void 0 ? void 0 : jQuery(window).on("elementor/frontend/init", async () => {
    const {
      elementorFrontend
    } = w; // Override Video API's and check for consent

    elementorFrontend.on("components:init", () => {
      for (const apiName of APIS) {
        const api = elementorFrontend.utils[apiName];

        if (api) {
          api.insertAPI = function () {
            const apiUrl = this.getApiURL();
            unblock(apiUrl).then(() => {
              this.elements.$firstScript.before(jQuery("<script>", {
                src: apiUrl
              }));
            });
            this.setSettings("isInserted", true);
          };
        }
      }
    }); // Implement auto play

    const handlerWait = elementorFrontend.elementsHandler.getHandler("video.default");

    if (handlerWait) {
      const handler = handlerWait !== null && handlerWait !== void 0 && handlerWait.then ? await handlerWait : handlerWait;
      const {
        onInit
      } = handler.prototype; // `onInit` is called for each video element, event it is blocked

      handler.prototype.onInit = function (...args) {
        const {
          $element
        } = this;
        $element === null || $element === void 0 ? void 0 : $element.get(0).addEventListener(OPT_IN_CONTENT_BLOCKER, async ({
          detail: {
            gotClicked
          }
        }) => {
          if (gotClicked) {
            const settings = $element.data("settings");
            settings.autoplay = true;
            $element.data("settings", settings);
          }
        });
        return onInit.apply(this, args);
      };
    }
  });
}

export { overwriteElementorVideoApi };