/* istanbul ignore file: we do not need to care about the entry point file as errors are detected through integration tests (E2E) */

/**
 * Entrypoint for plain website. This file is loaded on all pages.
 */
import { ready } from "@devowl-wp/cookie-consent-web-client";
import { memorizeJQueryEvent, hijackJqueryFn } from "@devowl-wp/headless-content-unblocker";
import { listenOptInForContentBlocker, overwriteElementorVideoApi } from "./others";
const hijackJqueryFns = ["fitVids", "mediaelementplayer", "prettyPhoto", "gMap"];
listenOptInForContentBlocker();
hijackJqueryFn(hijackJqueryFns);
overwriteElementorVideoApi();
ready(() => {
  // jQuery fn cannot be ready yet, let's wait for DOM ready
  hijackJqueryFn(hijackJqueryFns);
  memorizeJQueryEvent(document, document, "tve-dash.load"); // Thrive Leads

  memorizeJQueryEvent(document, document, "mylisting/single:tab-switched"); // My Listing theme
}, "interactive");